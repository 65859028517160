import React from "react";
import Layout from "../../templates/layout";
import SEO from "../../components/seo/seo";
import Hero from "../../components/hero/hero";

import HaveQuestions from "../../components/haveQuestions/haveQuestions";
import BrandLogo from "../../images/logos/jevtana-logo.svg";
import QuestionsIcon from "../../images/icons/icon-questions-jevtana.svg";
// import HeroIcon from "../../images/icons/icon-forms-jevtana-hero.svg";
import IconCard from "../../components/iconCard/iconCard"
import enrollmentForm from "../../images/icons/elitek/enrollment-form.png"
import spanishEnrollmentForm from "../../images/icons/elitek/spanish-enrollment-form.png";

import patientOverviewFlashcard from "../../images/icons/elitek/patientOverviewIcon.jpg";
import spanishOverviewFlashcard from "../../images/icons/jevtana/spanishOverviewFlashcard.jpg";
import HeroIcon from "../../images/icons/icon-enroll-jevtana-hero-banner.svg"

import ExpandingCard from "../../components/ExpandingCard/ExpandingCard";


import Isi from './components/Isi';
import ResourceLinks from "./components/ResourceLinks";
import BackToTop from "../../components/backToTop/BackToTop";

const pageContent = () => (
  <Layout pageid="page-forms" pageClass="patient-jevtana-support mobile-height-hero">
    <SEO
      title="Find CareASSIST patient resources for JEVTANA® (cabazitaxel) | Patient Site"
      keywords="CareASSIST, Sanofi, JEVTANA® (cabazitaxel), Patient Site, Resource Support"
      description="Find resources and support options for JEVTANA with CareASSIST. See Prescribing Info, including Boxed WARNING, and Patient Info"
    />

    <Hero
      headline="Resource support"
      brandLogo={BrandLogo}
      brandAlt="Jevtana Logo"
      heroImgClass="hero-icon"
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      iconAlt="Resource Support Icon"
      hasButton={false}
      copy="Get connected to independent support organizations and resources to support your treatment journey"
    />

<section className="program-download p-t-40 mobile-p-t-0">
      <div className="program-download-inner jevtana">
        <div className="expanding-card-content header m-b-30 p-l-35 mobile-p-l-0">
          <h2 className="m-t-0 mobile-p-l-15 lh100">Program essentials</h2>
        </div>
        <div className="grid-cols-sm-1 grid-cols-2 gap-30 p-l-35 p-r-35">
              <IconCard
                icon={enrollmentForm}
                alt="Enrollment Icon"
                cardClass="download-card jevtana"
                cardTitle="Enrollment form"
                cardText=""
                isExternalLink={true}
                link="../../pdfs/careassist-program-enrollment-form.pdf"
                linkClass="download-icon"
                linkTitle="Download the enrollment form"
                isBlur={true}
                linkId="patient-jevtana-resource-enrollment-form"
              />
              <IconCard
                icon={spanishEnrollmentForm}
                alt="Spanish enrollment form"
                cardClass="download-card jevtana"
                cardTitle="Spanish enrollment form"
                cardText=""
                isExternalLink={true}
                link="../../pdfs/careassist-spanish-enrollment-form.pdf"
                linkClass="download-icon"
                linkTitle="Download the Spanish enrollment form"
                isBlur={true}
                linkId="patient-jevtana-resource-spanish-enrollment-form"
              />
              <IconCard
                icon={patientOverviewFlashcard}
                alt="CareASSIST patient overview flashcard"
                cardClass="download-card jevtana"
                cardTitle="CareASSIST patient overview flashcard"
                cardText=""
                isExternalLink={true}
                link="../../pdfs/careassist-patient-support-program-overview-resource-patient.pdf"
                linkClass="download-icon"
                linkTitle=""
                isBlur={true}
                linkId="patient-jevtana-resource-flashcard"
              />
              {/* <IconCard
                icon={spanishOverviewFlashcard}
                alt="Spanish patient overview flashcard"
                cardClass="download-card jevtana"
                cardTitle="CareASSIST Spanish patient overview flashcard"
                cardText=""
                isExternalLink={false}
                link="../../pdfs/careassist-patient-support-program-overview-resource-spanish-version.pdf"
                linkClass="download-icon"
                linkTitle=""
              /> */}
        </div>
      </div>
    </section>

    <section className="content-section p-t-20" id="overview">
        <div className="expanding-card-content header">
          <h2 className="fs-32">Online resources*</h2>
        </div>
        <ResourceLinks/>
        <Isi/>
      <HaveQuestions icon={QuestionsIcon} />
    </section>
      <div className="back-to-top-container">
          <BackToTop />
      </div>
  </Layout>
);

export default pageContent;
