import React from "react";

import ExpandingCard from "../../../components/ExpandingCard/ExpandingCard";
import IconCard from "../../../components/iconCard/iconCard";

import EnrollmentIcon from "../../../images/icons/jevtana/enrollmentIcon.png";
import spanishEnrollmentIcon from "../../../images/icons/jevtana/spanishEnrollmentIcon.png";
import flashcardIcon from "../../../images/icons/jevtana/flashcardIcon.png";
import patientOverviewFlashcard from "../../../images/icons/jevtana/patientOverviewFlashcard.jpg";
import spanishOverviewFlashcard from "../../../images/icons/jevtana/spanishOverviewFlashcard.jpg";

const ResourceLinks = () => {
  return (
    <>
      <div className=" full p-b-0">
          <div className="mt-1 ">
            <p className="mb-2 fs-16">
            Our Care Managers can provide guidance around additional support resources. 
            </p>
            <div className="grid-cols-sm-1 grid-cols-2 gap-20">
            <IconCard
              cardClass="external-link-card jevtana clickable-title"
              cardTitle="HealthCare.gov"
              alt="HealthCare.gov"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://healthcare.gov"
              isClickableTitle={true}
              modalLinkId="patient-jevtana-resource-healthcare-gov"
            />
            <IconCard
              cardClass="external-link-card jevtana clickable-title"
              cardTitle="Medicaid.gov"
              alt="Medicaid.gov"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://medicaid.gov"
              isClickableTitle={true}
              modalLinkId="patient-jevtana-resource-medicaid-gov"
            />
            <IconCard
              cardClass="external-link-card jevtana clickable-title"
              cardTitle="Medicare.gov"
              alt="Medicare.gov"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://medicare.gov"
              isClickableTitle={true}
              modalLinkId="patient-jevtana-resource-medicare-gov"
            />
            <IconCard
              cardClass="external-link-card jevtana clickable-title"
              cardTitle="Patient Advocate Foundation"
              alt="Patient Advocate Foundation"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://www.patientadvocate.org/"
              isClickableTitle={true}
              modalLinkId="patient-jevtana-resource-patientadvocate-org"
            />
            <IconCard
              cardClass="external-link-card jevtana clickable-title"
              cardTitle="Patient Advocate Network Foundation"
              alt="Patient Advocate Network Foundation"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://www.panfoundation.org/"
              isClickableTitle={true}
              modalLinkId="patient-jevtana-resource-panfoundation-org"
            />
            <IconCard
              cardClass="external-link-card jevtana clickable-title"
              cardTitle="American Cancer Society"
              alt="American Cancer Society"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://cancer.org"
              isClickableTitle={true}
              modalLinkId="patient-jevtana-resource-cancer-org"
            />
            <IconCard
              cardClass="external-link-card jevtana clickable-title"
              cardTitle="Cancer Support Community"
              alt="Cancer Support Community"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://www.cancersupportcommunity.org/"
              isClickableTitle={true}
              modalLinkId="patient-jevtana-resource-cancersupportcommunity-org"
            />
            <IconCard
              cardClass="external-link-card jevtana clickable-title"
              cardTitle="National Comprehensive Cancer Network<sup>®</sup>"
              alt="National Comprehensive Cancer Network<sup>®</sup>"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://www.nccn.org/patientresources/patient-resources"
              isClickableTitle={true}
              modalLinkId="patient-jevtana-resource-nccn-org"
            />
            <IconCard
              cardClass="external-link-card jevtana clickable-title"
              cardTitle="CancerCare"
              alt="CancerCare"
              cardText=""
              isModalLink={true}
              modalLink="https://www.cancercare.org/"
              isClickableTitle={true}
              modalLinkId="patient-jevtana-resource-cancercare-org"
            />
              <IconCard
                alt="FindHelp.org"
                cardClass="external-link-card jevtana clickable-title"
                cardTitle="FindHelp.org"
                cardText=""
                isModalLink={true}
                modalLink="https://www.Findhelp.org/"
                isClickableTitle={true}
                modalLinkId="patient-jevtana-resource-findhelp-org"
              />
            </div>
          </div>
          <p className="hang m-b-0">
          *This listing is provided as a resource only and does not constitute an endorsement by Sanofi of any particular organization or its programming. Additional resources on this topic may be available and should be investigated. Sanofi does not review or control the content of non-Sanofi websites.             
          </p>
        </div>
    </>
  );
};

export default ResourceLinks;
